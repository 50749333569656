<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			<v-col cols="12" sm="8" class="pa-0">
				<div class="text-h5 primary--text">Page de test Administrateur</div>
                <div class="mt-6">Test notifications</div>
                <v-row class="ma-0 mt-2">
                    <v-col class="pa-0 pr-md-2" cols="12" md="6">
                        <v-select class="mb-4" :items="listeCentres" label="Centre" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" return-object v-model="centre_selectionne"></v-select>
                    </v-col>
                    <v-col class="pa-0 pl-md-2" cols="12" md="6">
                        <v-autocomplete class="mb-4" outlined clearable dense style="max-width: 400px" :filter="customFilter" :items="liste_membres" hide-details label="Destinataire" v-model="formulaire_notification.id_utilisateur" item-value="user.id">
                            <template slot="item" slot-scope="data">
                                <span>{{ data.item.user.nom + ' ' +  data.item.user.prenom}}</span>
                            </template>
                            <template slot="selection" slot-scope="data">
                                <span>{{ data.item.user.nom + ' ' +  data.item.user.prenom}}</span>         
                            </template>
                        </v-autocomplete>
                    </v-col>
				</v-row>
                <v-textarea outlined hide-details class="mt-4" label="Texte notification" v-model="$v.formulaire_notification.texte.$model"></v-textarea>
                <v-row class="ma-0 mt-2">
                    <v-spacer/>
                    <v-btn outlined :disabled="$v.formulaire_notification.$invalid" @click="onClickEnvoiNotification" :loading="chargement_notification">Envoi</v-btn>
                </v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
    import axios from 'axios'
    import { required } from 'vuelidate/lib/validators'
    export default {
        created() {
            this.$store.commit('majTitrePage', 'Test admin')
        
        },
        mounted() {
            this.$store.dispatch('getListeCentres').then( () => {
                this.centre_selectionne = this.$store.getters.membreCourant.centre
                this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
			})
        },
        data() {
			return {
                centre_selectionne: null,
                chargement_notification: false,
                formulaire_notification: {
                    texte: '',
                    id_utilisateur: 1   // pour test 1 = moi
                },
                
                
            }
		},
		computed: {
            listeCentres() {
				return this.$store.getters.liste_centres
			},
            liste_membres() {
				return this.$store.getters.annuaire
			},
		},
		methods: {
            affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
            customFilter(item, queryText, itemText) {
                const searchText = queryText.toLowerCase()

                return item.user.nom.toLowerCase().indexOf(searchText) > -1 || 
                        item.user.prenom.toLowerCase().indexOf(searchText) > -1
            },
            onClickEnvoiNotification() {
                this.chargement_notification = true
                axios.post('api/envoi_notification', this.formulaire_notification)
                    .then( (reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors de l\'envoi');
                    })
                    .then(() => {
                        this.chargement_notification = false
                        this.formulaire_notification.texte = ''
                    })
            },
            snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
            },

        },
        validations: {
            formulaire_notification: {
                texte: {
                    required,
                },
            }
        },
        watch: {
            centre_selectionne() {
                this.formulaire_notification.id_utilisateur = null
                this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
			},
        }
  	}
</script>

<style>


</style>

